@import "./fonts.scss";

.text {
  &--shadowed {
    @media (max-width: 770px) {
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 1);
    }
  }
}

.custom-scroll {
  /* width */
  &::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 4px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.5);
    border-radius: 4px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 1);
  }
}
