.scene {
  &--1 {
    background-image: url("../../../assets/images/scenes/Desktop_3.png");
    background-size: cover;
    @media (min-width: 1919px) {
      background-size: auto;
    }
    @media (max-width: 770px) {
      background-position-x: 73%;
    }
    @media (max-width:450px) {
      background-image: url("../../../assets/images/scenes/Desktop_3.png");
      // background-position: center center;
    }
  }
  &--2 {
    background-image: url("../../../assets/images/scenes/Desktop_4.png");
    background-size: cover;
    @media (max-width:450px) {
      background-image: url("../../../assets/images/scenes/Desktop_4.png");
      background-position: center center;
    }
  }
  &--3 {
    background-image: url("../../../assets/images/scenes/Desktop_5.png");
    background-size: cover;
    @media (max-width:450px) {
      background-image: url("../../../assets/images/scenes/Desktop_5.png");
      background-position: center center;
    }
  }
  &--6 {
    background-image: url("../../../assets/images/scenes/Desktop_3.png");
    background-size: cover;
    @media (max-width: 770px) {
      background-position: center;
    }
    @media (max-width:450px) {
      background-image: url("../../../assets/images/scenes/Desktop_3.png");
      background-position: center center;
    }
  }
  &--7 {
    background-image: url("../../../assets/images/scenes/Desktop_3.png");
    background-size: cover;
    @media (max-width:450px) {
      background-image: url("../../../assets/images/scenes/Desktop_3.png");
      background-position: center center;
    }
  }
  &--8 {
    background-image: url("../../../assets/images/scenes/Desktop_3.png");
    background-size: cover;
    @media (max-width: 770px) {
      background-position: center;
    }
    @media (max-width:450px) {
      background-image: url("../../../assets/images/scenes/Desktop_3.png");
      background-position: center center;
    }
  }
  &--9 {
    background-image: url("../../../assets/images/scenes/Desktop_3.png");
    background-size: cover;
    @media (max-width: 770px) {
      background-position: center;
    }
  }
  &--10 {
    background-image: url("../../../assets/images/scenes/Desktop_5.png");
    background-size: cover;
    @media (max-width: 770px) {
      background-position: center;
    }
    @media (max-width:450px) {
      background-image: url("../../../assets/images/scenes/Desktop_5.png");
      background-position: center center;
    }
  }
}
